<style lang="scss" scoped>
	@import './show.page';

.page {
  padding: 15px 40px 70px 60px;
  font-size: 1vw;
}
.page-route-select{
  display: flex;
  align-items: center;
}
.page-route-select-text{
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon{
  color: #FF9954;
  margin-right: 20px;
}
.page-title{
  margin-top: 45px;
  font-weight: 700;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
}
.page-table-line-special{
  background: #F7F7F7;
}
.order-details__container {
  display: flex;
  margin: 3em 0em;
}
.order-details__wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 10em;
}
.order-details__wrapper__main-title {
  font-size: 1.8em;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #606060;
  margin-bottom: 1em;
}
.order-details__wrapper__common-info {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: #A1A1A1;
  margin-bottom: 0.6em;
}
.order-details__wrapper__bg-green-colored-info {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: #30AA4C;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  background-color: rgba(42, 205, 114, 0.1);
  margin-bottom: 0.6em;
}
.bold-text {
  color: #707070;
  font-weight: 600;
}
.pending {
  color: #30AA4C;
}
.ordered {
  background-color: #FFF1E7;
  color: #FF7110;
  padding: 5px;
  text-align: center;
  border-radius: 8px;
}
.whatsapp-icon {
  color: #fff;
  background-color: #30AA4C;
  border-radius: 100%;
  padding: 2.5px;
  font-size: 18px;
}
.order-details__total-value-wrapper {
  display: flex;
  width: fit-content;
  padding: 1.3em;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgba(229, 229, 229, 1);
  border-radius: 8px;
}
.order-details__total-value-title {
  color: #202020;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 17px;
}
.order-details__actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0em;
}
.order-details__actions-wrapper {}
.order-details__input-title-wrapper {}
.order-details__input-title {
  font-weight: 400;
  font-size: 1.6em;
  color: #898989;
}
.order-details__input-wrapper {
  display: flex;
  align-items: center;
}
.order-details__input {
  width: 30vw;
  text-align: start;
  padding: 1.8em 1em;
}
.order-details__input-icon {
  position: relative;
  left: -30px;
  color: #8F8F8F;
}
.order-details__btn-actions-wrapper {
  display: flex;
}
.page-table-container {
  margin: 2em 0em;
  overflow-x: auto;
}
.page-table-content {
  min-width: 1300px;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 0.4em 0em;
}
.page-table-header-text {
  padding: 0.2em 0.3em;
  /* padding-left: 10px; */
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1em;
  color: #605F5F;
  align-items: center;
  padding: 0.5em 0em;
}
.page-table-desc-column{
  padding: 0.2em 0.5em;
}
.seemore-button-containear{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 70px;
  overflow-x: auto;
}
.brand-width {
  width: 30%;
  display: flex;
}
.qty-width {
  width: 10%;
}
.package-width {
  width: 16%;
}
.unit-value-width {
  width: 16%;
}
.package-value-width {
  width: 16%;
}
.total-width {
  width: 10%;
}
@media only screen and (max-width: 860px) {
  .page-route-select-text {
	font-size: 1.96em;
  }
  .page-title {
	font-size: 3.32em;
  }
  .order-details__wrapper__main-title {
	font-size: 2.4em;
  }
  .order-details__wrapper__common-info {
	font-size: 1.8em;
  }
  .order-details__wrapper__bg-green-colored-info {
	font-size: 1.8em;
	padding: 9px;
  }
  .whatsapp-icon {}
  .order-details__total-value-title {
	font-size: 1.8em;
  }
  .order-details__input-title {
	font-size: 2.2em;
  }
  .page-table-header-text {
	font-size: 1.79em;
  }
  .page-table-row {
	font-size: 1.6em;
  }
  .order-details__input {
	width: 55vw;
	padding: 1.2em 0em;
  }
  .order-details__total-value-wrapper {
	width: 45%;
  }
  .button-text {
	font-size: 1.6em;
  }
}
@media only screen and (max-width: 480px) {
  .page-route-select-text {
	font-size: 3.16em;
  }
  .page-title {
	font-size: 6.7em;
  }
  .order-details__wrapper__main-title {
	font-size: 3.6em;
  }
  .order-details__wrapper__common-info {
	font-size: 3em;
  }
  .order-details__wrapper__bg-green-colored-info {
	font-size: 3em;
	padding: 2px;
  }
  .whatsapp-icon {}
  .order-details__total-value-title {
	font-size: 3.4em;
  }
  .order-details__input-title {
	font-size: 3.8em;
  }
  .page-table-header-text {
	font-size: 3.49em;
  }
  .page-table-row {
	font-size: 3.2em;
  }
  .button-text {
	font-size: 2.8em;
  }
  .page {
	padding: 20px;
  }
}

</style>

<template>
  <div class="page" v-if="!is_loading">
	<div class="page-route-select not-print">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <router-link to="/ofertas" class="page-route-select-text">
        Ofertas
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <router-link to="/ofertas/pedidos" class="page-route-select-text">
        Ver historico de pedidos
      </router-link>
	  <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Pedido #{{ $route.params.id }}
      </div>
    </div>
	<div class="page-title">Pedido: #{{ $route.params.id }}</div>
	<div class="order-details__container">
	  <div v-if="store" class="order-details__wrapper">
		<span class="order-details__wrapper__main-title">Comprador</span>
		<span class="order-details__wrapper__common-info">{{ store.loj_nomeFantasia }}</span>
		<span class="order-details__wrapper__common-info"><strong class="bold-text">Endereço:</strong> {{ store.loj_endereco }}, {{ store.loj_bairro }} - {{ store.cid_nome }}/{{ store.est_sigla }}</span>
		<span class="order-details__wrapper__common-info"><strong class="bold-text">Fone:</strong> {{ store.loj_telefone }}</span>
		<span class="order-details__wrapper__common-info"><strong class="bold-text">CNPJ:</strong>  {{ store.loj_cnpj | cnpj }}</span>
	  </div>
	  <div v-if="provider" class="order-details__wrapper">
		<span class="order-details__wrapper__main-title">Fornecedor</span>
		<span class="order-details__wrapper__bg-green-colored-info">{{ provider.for_nome }}</span>
		<span class="order-details__wrapper__common-info"><strong class="bold-text">Nome:</strong> {{ provider.for_contato }}</span>
		<span class="order-details__wrapper__common-info"><strong class="bold-text">Email:</strong> {{ provider.for_email }}</span>
		<span class="order-details__wrapper__common-info">
			<strong class="bold-text">Fone:  </strong>  
			<i class="fab fa-whatsapp whatsapp-icon"></i>
			{{ provider.for_telefone }}
		</span>
	  </div>
	  <div class="order-details__wrapper">
		<span class="order-details__wrapper__main-title">Detalhe do Pedido</span>
		<span class="order-details__wrapper__common-info"><strong class="bold-text">Pedido:</strong>  #{{ order.pedido_id }}</span>
		<span class="order-details__wrapper__common-info"><strong class="bold-text">Status:</strong><span class="status" :class="translateStatusClass(order.status)"> {{ translateStatus(order.status) }}</span></span>
		<div class="order-details__wrapper__common-info">
		  OBS: {{ order.obs ? order.obs : '--' }}
		</div>
	  </div>
	</div>
	<div v-if="order" class="order-details__total-value-wrapper">
	  <img style="margin-right: 10px;" src="@/assets/database-icon.png" alt="">
	  <span class="order-details__total-value-title">Valor Total: {{ getTotal(order) | currency }}</span>
	</div>
	<div v-if="order" class="order-details__actions-container not-print">
	  <div class="order-details__actions-wrapper">
		<div class="order-details__input-title-wrapper">
		  <span class="order-details__input-title">Buscar</span>
		</div>
		<div class="order-details__input-wrapper">
		  <input class="order-details__input" v-on:keyup="search" v-model="search_terms" type="search" placeholder="Pesquisar itens do pedido">
		  <span class="material-icons-outlined order-details__input-icon">
		    search
		  </span>
		</div>
	  </div>
	  <div class="order-details__btn-actions-wrapper">
		<StandardButton class="button-text" :action="() => { $router.push('/pedidos') }" :iconName="'arrow_back'" :text="'Voltar'"/>
	    <StandardButton style="margin-left: 10px;" class="button-text" :action="print" :iconName="'print'" :text="'Imprimir'"/>
	  </div>
	</div>
	<div class="page-table-container">
      <div class="page-table-content">
        <div class="page-table-header">
          <div class="page-table-header-text brand-width">Produto/Marca</div>
          <div class="page-table-header-text qty-width">QTD</div>
          <div class="page-table-header-text package-width">Embalagem</div>
          <div class="page-table-header-text unit-value-width">Valor Unitário</div>
          <div class="page-table-header-text package-value-width">Valor Embalagem</div>
          <div class="page-table-header-text total-width">Total</div>
        </div>
        <div v-for="(item, idx) in visible_products()" 
		     :key="item.pro_id"
			 :class="{'page-table-line-special': idx % 2 != 0 }">
          <div class="page-table-row-containear">
            <div class="page-table-row">
              <div class="page-table-desc-column brand-width">
				{{ item.product.pro_descricao }}
                <br v-if="item.campaign_item">
                <small style="margin-left: 10px;" v-if="item.campaign_item" class="obs">
                  {{ item.campaign_item.observacao }}
                </small>
			  </div>
              <div class="page-table-desc-column qty-width">
				<input type="number" :value="item.quantidade" disabled />
			  </div>
              <div class="page-table-desc-column package-width">{{ item.product.pro_embalagem }}</div>
              <div class="page-table-desc-column unit-value-width">{{ item.preco_unitario | currency }}</div>
			  <div class="page-table-desc-column package-value-width">{{ item.preco_embalagem | currency }}</div>
			  <div class="page-table-desc-column total-width">{{ item.quantidade*item.preco_embalagem | currency }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tr v-show="total_items > items_by_page_value" class="seemore-button-containear" >
      <td class="text-center" colspan="6">
        <Paginacao classes="orange justify-end"
                   :items_by_page_value="items_by_page_value"
                   :total="total_items"
                   :page="page"
                   v-on:change="update_page($event)">
        </Paginacao>
      </td>
    </tr>
  </div>

	<!-- <div class="body">

		<div class="container-fluid header-info" v-if="!is_loading">

			<div class="row">
				<div class="col">
                    <h4 class="header-title"><i class="fa fa-shopping-bag" aria-hidden="true"></i>Pedido</h4>
                    <h5 class="header-subtitle">#{{$route.params.id}}</h5>
                </div>
			</div>

			<div class="row">

				<div class="col col-lg-3">
					<div class="info-block" v-if="store">
						<span class="tag">comprador</span>
						<span class="header">{{ store.loj_nomeFantasia}}</span>
						<span>{{ store.loj_endereco }}, {{ store.loj_bairro }} - {{ store.cid_nome }}/{{ store.est_sigla }}</span>
						<span>Fone: {{ store.loj_telefone }}</span>
						<span>CNPJ: {{ store.loj_cnpj | cnpj }}</span>
					</div>
				</div>
				<div class="col">
					<div class="info-block" v-if="provider">
						<span class="tag">fornecedor</span>
						<span class="header"><span class="provider">{{ provider.for_nome }}</span></span>
						<span>{{ provider.for_contato }}</span>
						<span>{{ provider.for_email }}</span>
						<span>Fone: {{ provider.for_telefone }}</span>
					</div>
				</div>

				<div class="col">
					<div class="info-block">
						<span class="tag">detalhes do pedido</span>
						<span>Pedido: #{{ order.pedido_id }}</span>
						<span>
							Status:
							<span class="status" :class="translateStatusClass(order.status)" >{{ translateStatus(order.status) }}</span>
						</span>
						<div class="text-box" v-if="order.obs">
							OBS: {{ order.obs }}
						</div>
					</div>
				</div>

				<div data-v-36816753="" class="col"></div>

			</div>

			<div class="row action-buttons">

				<div class="col" v-if="order">
					<button style="margin: 0;" class="info" disabled>
						<i class="fas fa-coins"></i>
						<span>Valor Total: {{ getTotal(order) | currency }}</span>
					</button>
				</div>

				<div class="col" v-if="order">
					<router-link :to="{ name: 'campaign-orders-index' }" tag="button" v-bind:class="'back'">
						<i class="fa fa-arrow-left" style="margin-right: 8px;font-size: 1.5rem;color: #71727d;padding-bottom: 2px;"></i>
						Voltar
					</router-link>
					<button v-on:click="print()">
						<i class="fas fa-print"></i>
						<span>Imprimir</span>
					</button>
				</div>

			</div>

			<div class="row search-bar">

				<div class="col">

					<span class="fa fa-search"></span>
					<input v-on:keyup="search" v-model="search_terms" type="search" placeholder="Pesquisar itens do pedido">

				</div>

				<div class="col"></div>

			</div>

			<div class="row">
				<div class="col shadow p-4">
					<div class="cc-loader" :class="{ hidden : !is_loading }">
						<img src="/images/loader.svg"/>
						<span>Carregando</span>
					</div>
					<table class="table" :class="{ hidden : is_loading }">

						<thead>
							<tr>
								<th class="pl-4">Produto/Marca</th>
								<th>Qtd</th>
								<th>Embalagem</th>
								<th>Valor Unitário</th>
								<th>Valor Embalagem</th>
								<th>Total</th>
							</tr>
						</thead>

						<tbody>

							<tr v-for="item in visible_products()" :key="item.pro_id">
								<td>
									{{ item.product.pro_descricao }}
                                    <br v-if="item.campaign_item">
                                    <small v-if="item.campaign_item" class="obs">
                                        {{ item.campaign_item.observacao }}
                                    </small>
								</td>
								<td>
									<input type="number" :value="item.quantidade" disabled />
								</td>
								<td>
									{{ item.product.pro_embalagem }}
								</td>
								<td >{{ item.preco_unitario | currency }}</td>
								<td >{{ item.preco_embalagem | currency }}</td>
								<td >{{ item.quantidade*item.preco_embalagem | currency }}</td>
							</tr>

						</tbody>

					</table>

				</div>

			</div>

		</div>

		<div class="container-fluid" :class="{ hidden: !is_loading }">
			<div class="row justify-content-center">
				<div class="d-flex">
					<img src="/images/loader.svg"/>
				</div>
			</div>
		</div>

	</div> -->

</template>
<script>
import Header from "../../components/cliente/base-components/Header";
import Breadcrumb from "@/components/shared/Breadcrumb";
import Pagination from "../../components/cliente/base-components/Pagination";
import * as CONSTANTS from "../../constants/constants";
import OrderService from "../../services/OrderService";
import ProductService from "../../services/ProductService";
import ProviderService from "../../services/ProviderService";
import ErrorHandlerService from "../../services/ErrorHandlerService";
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import Paginacao from "@/components/cliente/base-components/Pagination";

export default {
  components: {
    ccPagination: Pagination,
	StandardButton,
	Paginacao,
  },
  data() {
    return {
		page: 1,
        items_by_page_value: 30,
        total_items: 1000,
		search_terms: "",
		is_loading: true,
		CONSTANTS: CONSTANTS,
		order : null,
		provider: null,
		store: null,
		service: new OrderService(),
		provider_status_options: [
			{
				value: 'ALL',
				name: 'Todos'
			},
			{
				value: 'WINNERS_ONLY',
				name: 'Apenas Vencedores'
			}
      	],
		breadcrumbs : [
				{
					link: CONSTANTS.ROUTE_MONITORS,
					name: 'Monitor de Cotações'
				},
				{
					name: 'Pedido ' + this.$route.params.id
				}
			]
    	};
  	},
	methods: {
		update_page(page) {
		  this.page = page;
		  this.loadOrder()
		},
		visible_products() {
			return this.order.itens.filter(p => p.visible == undefined || p.visible )
		},
		search() {
			this.order.itens.forEach(p => {
				if(new RegExp(this.search_terms, 'i').test(p.product.pro_descricao)) {
					this.$set(p, 'visible', true);
				} else {
					this.$set(p, 'visible', false);
				}
			})
		},
		print() {
			self.print();
		},
		translateStatusClass(status) {
			switch(status) {
				case "AGUARDANDO_CONFIRMACAO":
					return "pending";
				case "CONFIRMADO":
					return "ordered";
			}
		},
		translateStatus(status) {
			switch(status) {
				case "AGUARDANDO_CONFIRMACAO":
					return "Aguardando Confirmação";
				case "CONFIRMADO":
					return "CONFIRMADO";
				default:
					return "Não identificado!"
			}
		},
		getTotal(order) {
			return order.itens.reduce((x, { preco_embalagem, quantidade }) => x + (preco_embalagem*quantidade), 0);
		},
		loadOrder() {
			this.service.getDirectSaleOrder(this.$route.params.id)
			.then(response => response.data)
			.then(data => {
				this.total_items = data.itens
				this.order = data;
				this.provider = data.fornecedor;
				this.store = data.loja;
				this.is_loading = false;
			}).catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		}
	},
	created(){
		this.loadOrder();
	}
}
</script>
